<template>
  <svg
    height="512px"
    style="enable-background: new 0 0 512 512"
    version="1.1"
    viewBox="0 0 512 512"
    width="512px"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="_x38_7-dropbox">
      <g>
        <g>
          <path
            d="M494.604,109.397L354.213,16.75L256.002,99.62l141.547,88.315L494.604,109.397z M494.604,109.397"
            style="fill: #1f8eef"
          />
          <path
            d="M157.788,16.75L17.397,109.37l97.083,78.564L256.002,99.62L157.788,16.75z M157.788,16.75"
            style="fill: #1f8eef"
          />
          <path
            d="M256.002,276.272l-98.214,82.872L17.397,266.495l97.05-78.561L256.002,276.272l141.52-88.338     l97.082,78.561l-140.391,92.649L256.002,276.272z M256.002,276.272"
            style="fill: #1f8eef"
          />
          <path
            d="M361.314,390.245c-2.475,1.646-5.344,2.452-8.174,2.452c-3.432,0-6.832-1.193-9.6-3.497     l-87.539-73.866l-87.54,73.866c-5.046,4.247-12.287,4.665-17.809,1.045l-43.781-28.89v44.177l149.422,89.718l148.834-89.718     v-44.204L361.314,390.245z M361.314,390.245"
            style="fill: #1f8eef"
          />
        </g>
      </g>
    </g>
    <g id="Layer_1" />
  </svg>
</template>
